<template>
  <div class="d-flex flex-wrap">
    <div @click="$emit('input', tab)" v-for="(tab, i) in tabs" :class="{'tab--active': tab === $attrs.value, 'mr-4': i !== tabs.length - 1}" :key="i" class="text-capitalize mb-6 font-weight-medium status-type d-flex justify-center align-center">
      {{ tab }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleTabs",
  props: {
    tabs: Array,
  }
};
</script>

<style scoped lang="scss">
.status-type {
  padding: 8px 14px;
  gap: 10px;

  width: auto;
  height: 34px;

  background: #F9F8F8;
  border: 1px solid #F9F8F8;
  border-radius: 100px;
  cursor: pointer;
  transition: all .3s;

  &.tab--active {
    background: #F9F4EE;
    border: 1px solid #E2D6C7;
  }
}
</style>
